// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-verse-js": () => import("/Users/dkocherga/git_tree/snskrt/src/templates/verse.js" /* webpackChunkName: "component---src-templates-verse-js" */),
  "component---src-pages-dict-js": () => import("/Users/dkocherga/git_tree/snskrt/src/pages/dict.js" /* webpackChunkName: "component---src-pages-dict-js" */),
  "component---src-pages-index-js": () => import("/Users/dkocherga/git_tree/snskrt/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-verses-js": () => import("/Users/dkocherga/git_tree/snskrt/src/pages/verses.js" /* webpackChunkName: "component---src-pages-verses-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/dkocherga/git_tree/snskrt/.cache/data.json")

